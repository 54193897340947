import { createGlobalStyle, css } from "styled-components";

const GlobalStyles = createGlobalStyle(({ theme }) => css`
@font-face {
    font-family: "SharpGroteskBook";
    src: url('/fonts/SharpGroteskBook20-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${theme.palette.text.body};
    color: ${theme.palette.main.white};
  }
}
*:focus{
  outline: none;
}
/********** Add Your Global CSS Here **********/

body {
  -webkit-overflow-scrolling: touch;
  font-size: 15px;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin: 0;
  font-family: ${theme.fonts.body};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html, body {
  height: 100%;
}

#root {
  background-color: #fff;
  height: 100%;
  min-height: 100%;
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.page-subheading{
  max-width: 580px;
  margin: 10px auto;
}

a {
  word-break: break-all
}


select, 
input{
  font-size: 15px;
}

// // comment/uncomment to disable/enable scroll lock on modal open
// .ReactModal__Body--open {
//   overflow: hidden;
// }

.no-word-break{
  word-break: normal;
}

.mobile-hidden{
  display:none;
}

.desktop-hidden{
  display:block;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

@media only screen and (min-width: ${theme.breakpoints.M}) {
  .desktop-hidden{
    display:none;
  }
  .mobile-hidden{
      display:block;
}
}
`);

export default GlobalStyles;

import VARIABLES from "./variables";

const palette = {
  main: {
    primary: VARIABLES.COLORS.enchantedForestGreen, // check mark 0, 2 |
    secondary: VARIABLES.COLORS.enchantedForestGreen, // adding this for green in Nimble theme, primary will be red
    white: VARIABLES.COLORS.white, // color 16 | formInputElement 5
    black: VARIABLES.COLORS.black, // color 21 |
    border: VARIABLES.COLORS.darkGreenEleven,
    fieldWell: VARIABLES.COLORS.teaGreen, // bankStatementsPrompt 0 |
  },
  status: { // used for accent colors and status (ie arrears text)
    good: VARIABLES.COLORS.mantisGreen, // vibrant 
    goodAlt: VARIABLES.COLORS.enchantedForestGreen, // dull
    bad: VARIABLES.COLORS.salmon,
    badAlt: VARIABLES.COLORS.salmon,
    medium: VARIABLES.COLORS.orange, // 0, 49, 62  
    hardship: VARIABLES.COLORS.oceanBlue
  },
  grayscale: {
    lighterGray: VARIABLES.COLORS.lighterGray, // 5, 6, 7 lightGrayTwelve
    lightGray: VARIABLES.COLORS.lightGray, // 2 lightGrayFour
    gray: VARIABLES.COLORS.gray, // 1 lightGrayTwo
    mediumGray: VARIABLES.COLORS.mediumGray, // 15 lightGraySeventeen
    darkGray: VARIABLES.COLORS.darkGray, // 17 gray
    darkerGray: VARIABLES.COLORS.darkerGray  // color 46 newDashboardDarkGrey
  },
  text: {
    heading: VARIABLES.COLORS.enchantedForestGreen,
    body: VARIABLES.COLORS.themeBody,
    disclaimer: VARIABLES.COLORS.themeDisclaimer
  },
  header: {
    background: VARIABLES.COLORS.enchantedForestGreen, // 0, 1
    color: VARIABLES.COLORS.white, // 2
    mobileColor: VARIABLES.COLORS.enchantedForestGreen, // 3
    active: VARIABLES.COLORS.mantisGreen
  },
  footer: {
    border: VARIABLES.COLORS.white, // 0: border top &* only used in frontend
    background: VARIABLES.COLORS.themeBody, // 1: BackGround &* only used in frontend
    color: VARIABLES.COLORS.white // 2: Text &* only used in frontend
  },
  modal: {
    background: VARIABLES.COLORS.enchantedForestGreen, 
    overlay: VARIABLES.COLORS.lightFourteenGrayOpaque, // 2
    accent: VARIABLES.COLORS.mantisGreen, // 0
    header: VARIABLES.COLORS.enchantedForestGreen, // 3
  },
  input: {
    boxShadow: VARIABLES.COLORS.mediumGray, // &* used by frontend
    valid: VARIABLES.COLORS.mantisGreen, // 
    error: VARIABLES.COLORS.salmon, // 
    label: VARIABLES.COLORS.black, // formInputElement 0, 8 
    border: VARIABLES.COLORS.darkGray, // formInputElement 1, 6 | border: 3
    borderAlt: VARIABLES.COLORS.mantisGreen, // border: 5: extra light green &* only used in frontend
    placeholder: VARIABLES.COLORS.darkGray, // formInputElement 2 &* only used in frontend
    icon: VARIABLES.COLORS.darkGray, // 7: dollarsign &* only used in frontend
    checkbox: VARIABLES.COLORS.oceanBlue
  },
  thankYou: {
    icon: VARIABLES.COLORS.themeBody, // 3
    envelop: VARIABLES.COLORS.mantisGreen, // 6
    border: VARIABLES.COLORS.mantisGreen, // 1 
    background: VARIABLES.COLORS.teaGreen, // 2
    title: VARIABLES.COLORS.themeBody, // 4
    skip: VARIABLES.COLORS.themeBody, // 5 
  },
};

export { palette };

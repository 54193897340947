import { Config } from "./configType";

export const nimbleConfig: Config = {
  phone: '133 156',
  email: 'customercare@nimble.com.au',
  acn: '135 501 807',
  acl: '386010',
  compliance: '',
  faq: 'https://nimble.com.au/faq/',
  companyName: 'Nimble',
  termsAndConditions: 'https://nimble.com.au/documents/personal_loan_terms_and_conditions.pdf',
  privacy: 'https://nimble.com.au/policy/privacy/web.html',
  partnerLogo: 'nimble-partnership-logo.svg',
  useChatbot: false,
  directDebitBank: 'ANZ',
  directDebitBSB: '014 701',
  directDebitAccountNumber: '4753 4090 6',
  ezidebitUrl: 'https://nimble-australia.pay.ezidebit.com.au/'
};

export const nimbleFavicon = '/favicon-nimble.png';
import styled, { css } from "styled-components";

export default styled.div(({ theme }) => css`
  margin: 30px 0px;

  .label-desktop {
    width: 0;
    display: none;
    z-index: 1;

    @media only screen and (min-width: ${theme.breakpoints.XM}) {
      display: block;
    }
  }

  .label-mobile {
    display: block;
    padding: 0 20px;

    @media only screen and (min-width: ${theme.breakpoints.XM}) {
      display: none;
    }
  }
  
  .nav-svg {
    position: relative;
    z-index: 2;
    background: ${theme.palette.header.background};
    padding: ${(props) =>
      props.justifyAttribute === "left"
        ? "0px 0px 0px 17px"
        : "0px 17px 0px 0px"};
  }

  .link-container {
    display: flex;
    flex-direction: ${(props) =>
      props.justifyAttribute === "left" ? "row" : "row-reverse"};
    align-items: center;
    justify-content: ${(props) =>
      props.justifyAttribute === "left" ? "flex-start" : "end"};
    text-decoration: none;
    margin-bottom: 15px;
    overflow: hidden;

    p {
      color: ${theme.palette.header.color};
      font-weight: ${theme.fonts.headingWeight};
      margin: ${(props) =>
        props.justifyAttribute === "left" ? "0 0 0 10px" : "0 10px 0 0"};
      transition: 0.15s color linear;
      word-break: normal;
      white-space: nowrap;
      overflow: hidden;
      text-align: ${(props) =>
        props.justifyAttribute === "left" ? "left" : "right"};
    }
    &:hover {
      p {
        color: ${theme.palette.main.white};
      }
    }
    &.active {
      p {
        color: ${theme.palette.header.active};
      }
    }
  }

  @media only screen and (min-width: ${theme.breakpoints.XM}) {
    .link {
      text-decoration: none;
    }
    .link-container {
      p {
        color: ${theme.palette.header.color};
      }

      &:hover {
        p {
          color: ${theme.palette.header.active};
        }
      }
      &.active {
        p {
          color: ${theme.palette.header.active};
        }
      }
    }
  }
`);

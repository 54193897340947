// Note: only reference in theme files
const VARIABLES = Object.freeze({
  COLORS: {
    slate: "#212121",
    themeDisclaimer: "#bdbdbd",
    nimbleRed: "#DB1A1A",
    pink: "#FBD4CF",
    lightPink: "#FDE9E9",
    cream: "#FFFCF5", 
    darkGreen: "#017A68",
    lightGreen: "#D9EBE8",
    yellow: "#F7ED00",
    white: "#FFFFFF",
    whiteOpaque: "rgba(255, 255, 255, 0.75)",
    black: "#1E1E1E",
    lighterGray: "#F2F2F2",
    lightGray: "#DDDDDD",
    gray: "#CECECE",
    slateLight: "#A8A8A8",
    darkGray: "#808080",
    darkerGray: "#4F4F4F",
    lightFourteenGrayOpaque: "rgba(0, 0, 0, 0.8)",
    orange: "#EAA92C",
    brightGreen: "#00C072",
    darkRed: "#EA2C2E",
    darkerRed: "#c50000",
  },
});

export default VARIABLES;
